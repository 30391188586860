.social-icon {
  width: 80px;
  padding: 0px 15px;
}

.user-image {
  width: 90%;
  max-height: 400px;
  margin: 0 auto 30px;
  object-fit: contain;
}

.bg-pink {
  background-color: #000;
  z-index: -1;
}

.chevy-logo {
  width: 65px;
}

.contain-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100vh;
  position: relative;
  z-index: 10;
  color: #fff;
}

.design-1 {
  /* clip-path: polygon(0 0, 23% 0, 6% 54%, 0 60%); */
  background: #eb528a;
}

.design-3 {
  clip-path: polygon(100% 14%, 89% 31%, 100% 58%);
  background: #b5822b;
}
.design-4 {
  clip-path: polygon(100% 68%, 78% 100%, 100% 100%);
  background: #3b70ad;
}
.design-2 {
  clip-path: polygon(25% 92%, 12% 100%, 33% 100%);
  background: #3b70ad;
}

@media (max-width: 600px) {
  .social-icon {
    width: 90px;
  }
  .user-image {
    max-width: 350px;
  }
  .design-1 {
    -webkit-clip-path: polygon(0 0, 23% 0, 6% 54%, 0 60%);
    clip-path: polygon(0 0, 17% 0, 6% 21%, 0 28%);
    background: #eb528a;
  }
  .design-3 {
    -webkit-clip-path: polygon(100% 14%, 89% 31%, 100% 58%);
    clip-path: polygon(106% 14%, 93% 31%, 109% 47%);
    background: #b5822b;
  }
  .design-2 {
    -webkit-clip-path: polygon(25% 92%, 12% 100%, 33% 100%);
    clip-path: polygon(25% 97%, 12% 104%, 33% 101%);
    background: #3b70ad;
  }
  .design-4 {
    -webkit-clip-path: polygon(100% 68%, 78% 100%, 100% 100%);
    clip-path: polygon(100% 89%, 78% 100%, 100% 100%);
    background: #3b70ad;
  }
}
