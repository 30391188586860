.bg-design{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    /* background-image: url('./../../assets/img/background.png'); */
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }